<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Perform each of the unit conversions shown below:</p>

      <p class="mb-2">
        a) <number-value :value="number1" unit="\text{kg/m}^3" />
        <stemble-latex content="$\text{to g/L}$" />
      </p>
      <calculation-input v-model="inputs.input1" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        b) <number-value :value="number2" unit="\text{nm}^2" />
        <stemble-latex content="$\text{ to m}^2$" />
      </p>
      <calculation-input v-model="inputs.input2" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        c) <number-value :value="number3" unit="\text{cm}^{-1}" />
        <stemble-latex content="$\text{ to }\text{m}^{-1}$" />
      </p>
      <calculation-input v-model="inputs.input3" class="mb-4" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question185',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    number1() {
      return this.taskState.getValueBySymbol('number1').content;
    },
    number2() {
      return this.taskState.getValueBySymbol('number2').content;
    },
    number3() {
      return this.taskState.getValueBySymbol('number3').content;
    },
  },
};
</script>
